<script setup lang="ts">
import AdjustmentDetail from "@/views/warehouse-management/adjustment/AdjustmentDetail.vue"
import AdjustmentList from "@/views/warehouse-management/adjustment/AdjustmentList.vue"

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementAdjustment",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <AdjustmentDetail v-if="$route.query.id" :id="$route.query.id" />
    <AdjustmentList v-else />
  </div>
</template>
