<script setup lang="ts">
import PickWaveDetail from "@/views/warehouse-management/pick-wave/PickWaveDetail.vue";
import PickWaveList from "@/views/warehouse-management/pick-wave/PickWaveList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementPickWave",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <PickWaveDetail v-if="$route.query.id" :id="$route.query.id" />
    <PickWaveList v-else />
  </div>
</template>
