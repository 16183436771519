<script setup lang="ts">
import InventoryDetail from "@/views/oms/inventories/InventoryDetail.vue";
import InventoryList from "@/views/oms/inventories/InventoryList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "OmsInventory",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <InventoryDetail v-if="$route.query.id" :id="$route.query.id" />
    <InventoryList v-else />
  </div>
</template>
