<script setup lang="ts">
import InventorySearch from "@/views/warehouse-management/inventory-search/InventorySearch.vue"

definePage({
  meta: {
    layout: "default",
    subject: "WmsOperationInventorySearch",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <InventorySearch />
  </div>
</template>
