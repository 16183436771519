<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core"
import AdjustmentOperation from "@/views/warehouse-management/adjustment/AdjustmentOperation.vue"
import AdjustmentOperationV2 from "@/views/warehouse-management/adjustment/AdjustmentOperationV2.vue"

const isShowDebugInfo = useLocalStorage('isDebugEnabled', false)

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementAdjustment",
    action: "access",
  },
})
</script>

<template>
  <div>
    <AdjustmentOperationV2 v-if="isShowDebugInfo" />
    <AdjustmentOperation v-else />
  </div>
</template>
