<script setup lang="ts">
import InboundDetail from "@/views/warehouse-management/inbound/InboundDetail.vue";
import InboundList from "@/views/warehouse-management/inbound/InboundList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementInbound",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <InboundDetail v-if="$route.query.id" :id="$route.query.id" />
    <InboundList v-else />
  </div>
</template>
