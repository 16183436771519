<script setup lang="ts">
import SalesOrderDetail from "@/views/oms/orders/SalesOrderDetail.vue";
import SalesOrderList from "@/views/oms/orders/SalesOrderList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "OmsOrder",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <SalesOrderDetail v-if="$route.query.id" :id="$route.query.id" />
    <SalesOrderList v-else />
  </div>
</template>
