import type { App } from 'vue'

import * as Sentry from "@sentry/vue"

export default function (app: App) {
  // Only in production
  if (!import.meta.env.PROD) {
    return
  }

  Sentry.init({
    app,
    dsn: "https://f8d18f136ff14ef442fd78cdea7a0ed7@o4507891511721984.ingest.de.sentry.io/4507891515392080",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin, "demo-uk-api.packnship.app", "gmc-uk-api.packnship.app"],
      }),
    ],
    ignoreErrors: [
      /FetchError/i,
      /Failed to fetch dynamically imported module/i,
      /Unable to preload CSS/i,
      /error loading dynamically imported module/i,
      // ...
    ],

    // Error
    attachStacktrace: true,

    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [window.location.origin, "demo-uk.packnship.app", "demo-uk-api.packnship.app", "gmc-uk.packnship.app", "gmc-uk-api.packnship.app"],

    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}
