<script setup lang="ts">
import OutboundDetail from "@/views/warehouse-management/outbound/OutboundDetail.vue";
import OutboundList from "@/views/warehouse-management/outbound/OutboundList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementOutbound",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <OutboundDetail v-if="$route.query.id" :id="$route.query.id" />
    <OutboundList v-else />
  </div>
</template>
