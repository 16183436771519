<script setup lang="ts">
import CycleCountList from "@/views/warehouse-management/cycle-count/CycleCountList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementCycleCount",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <CycleCountList />
  </div>
</template>
