<script setup lang="ts">
import SkuDetail from "@/views/oms/products/SkuDetail.vue";
import SkuList from "@/views/oms/products/SkuList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "OmsProduct",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <SkuDetail v-if="$route.query.id" :id="$route.query.id" />
    <SkuList v-else />
  </div>
</template>
