import { layoutConfig } from "@layouts"

export const setTitle = (title: string = null) => {
  if (!title) {
    document.title = layoutConfig.app.title

    return
  }

  document.title = `${title} - ${layoutConfig.app.title}`
}
