<script setup lang="ts">
import PickWaveAutomation from "@/views/warehouse-management/pick-wave/PickWaveAutomation.vue"

definePage({
  meta: {
    layout: "default",
    subject: "WmsManagementPickWave",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <PickWaveAutomation />
  </div>
</template>
