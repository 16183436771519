<script setup lang="ts">
import VasDetail from "@/views/warehouse-management/vas/VasDetail.vue"
import VasList from "@/views/warehouse-management/vas/VasList.vue"

definePage({
  meta: {
    layout: "default",
    subject: "WmsOperationVas",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <VasDetail v-if="$route.query.id" :id="$route.query.id" />
    <VasList v-else />
  </div>
</template>
