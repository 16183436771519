<script setup lang="ts">
import ReceivingList from "@/views/warehouse-operation/receving/ReceivingList.vue"
import ReceivingOperationV2 from "@/views/warehouse-operation/receving/ReceivingOperationV2.vue"

definePage({
  meta: {
    layout: "default",
    subject: "WmsOperationReceiving",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <ReceivingOperationV2 v-if="$route.query.id" :id="$route.query.id" />
    <ReceivingList v-else />
  </div>
</template>
