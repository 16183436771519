<script setup lang="ts">
import AsnDetail from "@/views/oms/asns/AsnDetail.vue";
import AsnList from "@/views/oms/asns/AsnList.vue";

definePage({
  meta: {
    layout: "default",
    subject: "OmsAsn",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <AsnDetail v-if="$route.query.id" :id="$route.query.id" />
    <AsnList v-else />
  </div>
</template>
